export class RenewablesService {
  constructor(http) {
    this.http = http;
  }

  async summary(buildingIds, beginDate, endDate) {
    this.summaryParams = {
      buildingIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(`/renewables/summary`, this.summaryParams);
  }
  async summaryCSV() {
    if (!this.summaryParams) return;
    return this.http.post(
      `/renewables/summary?outputType=Csv`,
      this.summaryParams
    );
  }

  async avgDailySolarChart(buildingIds, beginDate, endDate) {
    this.avgDailySolarChartParams = {
      buildingIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(
      `/renewables/average-daily-solar-generation/chart`,
      this.avgDailySolarChartParams
    );
  }
  async avgDailySolarChartCSV() {
    if (!this.avgDailySolarChartParams) return;
    return this.http.post(
      `/renewables/average-daily-solar-generation/chart?outputType=Csv`,
      this.avgDailySolarChartParams
    );
  }

  async siteEnergySourceChart(buildingIds, beginDate, endDate, aliasId = null) {
    this.siteEnergySourceChartParams = {
      buildingIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    if (aliasId == null) {
      return this.http.post(
        `/renewables/site-energy-source-comparison/chart`,
        this.siteEnergySourceChartParams
      );
    } else {
      return this.http.post(
        `/renewables/site-energy-source-comparison/chart?aliasNameSetId=${aliasId}`,
        this.siteEnergySourceChartParams
      );
    }
  }
  async siteEnergySourceChartCSV() {
    if (!this.siteEnergySourceChartParams) return;
    return this.http.post(
      `/renewables/site-energy-source-comparison/chart?outputType=Csv`,
      this.siteEnergySourceChartParams
    );
  }

  async solarPowerGenChart(buildingIds, beginDate, endDate) {
    this.solarPowerGenChartParams = {
      buildingIds,
      beginDate: beginDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    return this.http.post(
      `/renewables/solar-power-generation/chart`,
      this.solarPowerGenChartParams
    );
  }
  async solarPowerGenChartCSV() {
    if (!this.solarPowerGenChartParams) return;
    return this.http.post(
      `/renewables/solar-power-generation/chart?outputType=Csv`,
      this.solarPowerGenChartParams
    );
  }
}
