export class CapitalPlanningService {
  constructor(http) {
    this.http = http;
  }

  /**
   * Get the data for the asset list table.
   * @param {number} siteId
   * @param {number[]} buildingIds
   * @param {number[]} equipmentTypeIds
   * @param {any[]} metricFilters
   * @param {boolean} useObserved
   */
  async assetList(
    siteId,
    buildingIds,
    equipmentTypeIds,
    metricFilters,
    useObserved,
    aliasId,
    pageSize,
    currentPage,
    search,
    orderBy,
    orderDesc,
    assetScoreFilter,
    assetReplacementYears,
    inflationRate
  ) {
    let data = {
      siteId: siteId,
      buildingIds: buildingIds,
      equipmentTypeIds: equipmentTypeIds,
      assetReplacementYears: assetReplacementYears,
    };

    let qParams = [];
    let query = '';

    qParams.push(`orderDesc=${orderDesc}`);
    if (aliasId) {
      qParams.push(`aliasNameSetId=${aliasId}`);
    }

    if (pageSize) {
      qParams.push(`pageSize=${pageSize}`);
    }

    if (currentPage) {
      qParams.push(`pageNumber=${currentPage}`);
    }

    if (orderBy) {
      qParams.push(`orderBy=${orderBy}`);
    }

    if (search.length > 0) {
      qParams.push(`search=${encodeURI(search.toLowerCase())}`);
    }

    if (qParams.length > 0) {
      query += '?';
      query += qParams.join('&');
    }

    if (metricFilters) {
      data.metricFilters = metricFilters;
    }
    if (useObserved !== undefined) {
      data.useObserved = useObserved;
    }
    if (assetScoreFilter !== undefined) {
      data.assetScoreFilter = assetScoreFilter;
    }

    if (inflationRate !== undefined) {
      data.inflationRate = inflationRate;
    }

    this.assetListParams = data;

    return this.http.post(`/capital-planning/asset-list${query}`, data);
  }

  /**
   * Get the CSV chart for the most recent asset list table request.
   */
  async assetListCsv(metricFilters, useObserved, replacementYears) {
    if (!this.assetListParams) return;
    if (metricFilters) {
      this.assetListParams.metricFilters = metricFilters;
    }
    if (useObserved !== undefined) {
      this.assetListParams.useObserved = useObserved;
    }
    if (replacementYears !== null) {
      this.assetListParams.additionalCsvFields = [
        {
          name: 'BudgetReplacement',
          data: replacementYears,
        },
      ];
    }
    return this.http.post(
      `/capital-planning/asset-list?outputType=Csv`,
      this.assetListParams
    );
  }

  /**
   * Get the chart data for the cash flow chart.
   * @param {number} siteId
   * @param {number[]} buildingIds
   * @param {number[]} equipmentTypeIds
   * @param {any[]} metricFilters
   * @param {boolean} useObserved
   * @param {number[]} replacements
   * @param {number} discountRate
   * @param {number} inflationRate
   */
  async cashFlowChart(
    siteId,
    buildingIds,
    equipmentTypeIds,
    metricFilters,
    useObserved,
    replacements,
    discountRate,
    inflationRate,
    assetScoreFilter,
    evaluationPeriod,
    aliasId = null
  ) {
    let data = {
      siteId: siteId,
      buildingIds: buildingIds,
      equipmentTypeIds: equipmentTypeIds,
    };
    if (metricFilters) {
      data.metricFilters = metricFilters;
    }
    if (useObserved !== undefined) {
      data.useObserved = useObserved;
    }
    if (replacements) {
      data.replacements = replacements;
    }
    if (discountRate !== undefined) {
      data.discountRate = discountRate;
    }
    if (inflationRate !== undefined) {
      data.inflationRate = inflationRate;
    }
    if (assetScoreFilter !== undefined) {
      data.assetScoreFilter = assetScoreFilter;
    }
    if (evaluationPeriod !== undefined) {
      data.evaluationPeriod = evaluationPeriod;
    }

    this.cashFlowParams = data;
    if (aliasId == null) {
      return this.http.post(`/capital-planning/cash-flow/chart`, data);
    } else {
      return this.http.post(
        `/capital-planning/cash-flow/chart?aliasNameSetId=${aliasId}`,
        data
      );
    }
  }

  /**
   * Get the CSV chart of the most recent cash flow API call.
   */
  async cashFlowChartCsv() {
    if (!this.cashFlowParams) return;
    return this.http.post(
      `/capital-planning/cash-flow/chart?outputType=Csv`,
      this.cashFlowParams
    );
  }

  /**
   * Get the filter options for the capital planning section.
   * @param {number} siteId
   * @param {number[]} buildingIds
   * @param {number} evaluationPeriod
   */
  async filterOptions(siteId, buildingIds, evaluationPeriod) {
    return this.http.post(`/capital-planning/options`, {
      siteId: siteId,
      buildingIds: buildingIds,
      evaluationPeriod: evaluationPeriod,
    });
  }

  /**
   * Get the equipment types for the selected subsystems.
   * @param {number} buildingIds
   * @param {number[]} subsystemIds
   */
  async equipmentTypes(buildingIds, subsystemIds) {
    return this.http.post(`/capital-planning/options/equipment-types`, {
      buildingIds: buildingIds,
      subsystemIds: subsystemIds,
    });
  }

  /**
   * Gets the metric scoring data for the interactive treemap for the capital
   * planning section.
   * @param {number} siteId
   * @param {number[]} buildingIds
   * @param {number[]=} equipmentTypeIds
   * @param {{metricId: number, maxValue: number, minValue: number, weight: number}[]=} metricFilters
   * @param {boolean=} useObserved
   * @param {number=} primaryMetricId
   * @param {number=} secondaryMetricId
   */
  async metricScoring(
    siteId,
    buildingIds,
    equipmentTypeIds,
    metricFilters,
    useObserved,
    primaryMetricId,
    secondaryMetricId,
    aliasId = null
  ) {
    /* we don't by default want to use all the parameters when making this API 
    call, to allow us to do sorting on the front-end. As such, we want to make
    the parameters flexible for being inserted onto the request's data object.*/
    this.metricScoringParams = {
      siteId: siteId,
      buildingIds: buildingIds,
    };
    if (equipmentTypeIds) {
      this.metricScoringParams.equipmentTypeIds = equipmentTypeIds;
    }
    if (metricFilters) {
      this.metricScoringParams.metricFilters = metricFilters;
    }
    if (typeof useObserved !== undefined && useObserved !== null) {
      this.metricScoringParams.useObserved = useObserved;
    }
    if (primaryMetricId) {
      this.metricScoringParams.primaryMetricId = primaryMetricId;
    }
    if (secondaryMetricId) {
      this.metricScoringParams.secondaryMetricId = secondaryMetricId;
    }

    if (aliasId == null) {
      return this.http.post(
        `/capital-planning/metric-scoring`,
        this.metricScoringParams
      );
    } else {
      return this.http.post(
        `/capital-planning/metric-scoring?aliasNameSetId=${aliasId}`,
        this.metricScoringParams
      );
    }
  }

  /**
   * Gets the treemap data object
   * @param {number} siteId
   * @param {number[]} buildingIds
   * @param {number[]=} equipmentTypeIds
   * @param {{metricId: number, maxValue: number, minValue: number, weight: number}[]=} metricFilters
   * @param {boolean=} useObserved
   * @param {number=} primaryMetricId
   * @param {number=} secondaryMetricId
   * @param {{}=} assetScoreFilter
   * @param {number} aliasId
   * @param {string} grouping
   * @param {number=} depth
   */
  async treemap(
    siteId,
    buildingIds,
    equipmentTypeIds,
    metricFilters,
    useObserved,
    primaryMetricId,
    secondaryMetricId,
    colors,
    useAbsoluteScale,
    assetScoreFilter,
    grouping = 'building',
    depth = 0,
    SortByPrimary = true,
    aliasId = null
  ) {
    /* we don't by default want to use all the parameters when making this API 
    call, to allow us to do sorting on the front-end. As such, we want to make
    the parameters flexible for being inserted onto the request's data object.*/
    this.metricScoringParams = {
      siteId: siteId,
      buildingIds: buildingIds,
      colors: colors,
      useAbsoluteScale: useAbsoluteScale,
      sortByPrimary: SortByPrimary,
    };

    this.metricScoringParams.grouping = grouping;
    this.metricScoringParams.depth = depth;

    if (equipmentTypeIds) {
      this.metricScoringParams.equipmentTypeIds = equipmentTypeIds;
    }
    if (metricFilters) {
      this.metricScoringParams.metricFilters = metricFilters;
    }
    if (typeof useObserved !== undefined && useObserved !== null) {
      this.metricScoringParams.useObserved = useObserved;
    }
    if (primaryMetricId != null) {
      this.metricScoringParams.primaryMetricId = primaryMetricId;
    }
    if (secondaryMetricId != null) {
      this.metricScoringParams.secondaryMetricId = secondaryMetricId;
    }
    if (assetScoreFilter !== undefined) {
      this.metricScoringParams.assetScoreFilter = assetScoreFilter;
    }

    if (aliasId == null) {
      return this.http.post(
        `/capital-planning/treemap/${grouping}/${depth}`,
        this.metricScoringParams
      );
    } else {
      return this.http.post(
        `/capital-planning/treemap/${grouping}/${depth}?aliasNameSetId=${aliasId}`,
        this.metricScoringParams
      );
    }
  }

  /**
   * Gets the asset details
   * @param {number} assetId
   * @param {{metricId: number, maxValue: number, minValue: number, weight: number}[]=} metricFilters
   * @param {boolean=} useObserved
   */
  async assetDetails(assetId, metricFilters, useObserved) {
    let payload = {
      metricFilters,
      useObserved,
    };

    return this.http.post(`/capital-planning/equipment/${assetId}`, payload);
  }

  /**
   * Get the CSV data for the most recent metric scoring API call.
   */
  async metricScoringCsv() {
    if (!this.metricScoringParams) return;
    return this.http.post(
      `/capital-planning/treemap/${this.metricScoringParams.grouping}/${this.metricScoringParams.depth}?outputType=Csv`,
      this.metricScoringParams
    );
  }

  async fcaTemplateCsv(siteId) {
    let payload = {
      siteId: siteId,
    };
    return this.http.post(`/capital-planning/fca/template`, payload);
  }

  // Trigger fca data export
  async fcaExportDataXlsx(siteId, buildingIds) {
    let payload = {
      siteId: siteId,
      buildingIds: buildingIds,
    };
    return this.http.post(`/capital-planning/fca/async-data-export`, payload);
  }

  // Get fca export results
  async fcaExportResults(siteId) {
    return this.http.get(`/capital-planning/fca/export-results/${siteId}`);
  }

  // Get fca import results
  async fcaUploadResults(siteId) {
    return this.http.pyGet(`/fca/results/${siteId}`);
  }

  async uploadFcaFiles(siteId, files, validateOnly = 'false') {
    let url = `/capital-planning/fca/assessments/${siteId}`;
    const formData = new FormData();

    //for multiple files, something like this,
    //probably enumerate 'file' string identifier with some ID for
    //unique key val or just iterate over
    //the files in python not sure
    //
    //files.forEach(file => formData.append('file',file, files.name))

    //just one file for now
    formData.append('file', files[0], files[0].name);
    // TODO: what about for multiple files?
    formData.append('validateOnly', validateOnly); // pass a boolean value

    return this.http.post(url, formData);
  }

  // Get blob from FCA upload storage
  async fcaBlob(siteId, fileName) {
    let url = `/fca/storage/${siteId}/${fileName}`;
    return this.http.pyGet(url, { responseType: 'arraybuffer' });
  }

  // Get blob from FCA download storage
  async getFCABlobFile(siteId, filename) {
    return this.http.get(
      `/capital-planning/fca/storage/${siteId}/${filename}`,
      {
        responseType: 'blob',
      }
    );
  }
}
