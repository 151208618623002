import { createAction, handleActions } from 'redux-actions';
import download from 'downloadjs';

export const types = {
  COSTS_CHART_REQUEST: 'app/savings/COSTS_CHART_REQUEST',
  COSTS_CHART_RESPONSE: 'app/savings/COSTS_CHART_RESPONSE',
  COSTS_OPTS_REQUEST: 'app/savings/COSTS_OPTS_REQUEST',
  COSTS_OPTS_RESPONSE: 'app/savings/COSTS_OPTS_RESPONSE',

  UNITS_CHART_REQUEST: 'app/savings/UNITS_CHART_REQUEST',
  UNITS_CHART_RESPONSE: 'app/savings/UNITS_CHART_RESPONSE',
  UNITS_OPTS_REQUEST: 'app/savings/UNITS_OPTS_REQUEST',
  UNITS_OPTS_RESPONSE: 'app/savings/UNITS_OPTS_RESPONSE',

  COMPARE_CHART_REQUEST: 'app/savings/COMPARE_CHART_REQUEST',
  COMPARE_CHART_RESPONSE: 'app/savings/COMPARE_CHART_RESPONSE',
  COMPARE_OPTS_REQUEST: 'app/savings/COMPARE_OPTS_REQUEST',
  COMPARE_OPTS_RESPONSE: 'app/savings/COMPARE_OPTS_RESPONSE',

  UNITS_COMPARE_CHART_REQUEST: 'app/savings/UNITS_COMPARE_CHART_REQUEST',
  UNITS_COMPARE_CHART_RESPONSE: 'app/savings/UNITS_COMPARE_CHART_RESPONSE',
  UNITS_COMPARE_OPTS_REQUEST: 'app/savings/UNITS_COMPARE_OPTS_REQUEST',
  UNITS_COMPARE_OPTS_RESPONSE: 'app/savings/UNITS_COMPARE_OPTS_RESPONSE',

  SAVINGS_TOTAL_REQUEST: 'app/savings/SAVINGS_TOTAL_REQUEST',
  SAVINGS_TOTAL_RESPONSE: 'app/savings/SAVINGS_TOTAL_RESPONSE',

  SAVINGS_FILTER_OPTS_REQUEST: 'app/savings/SAVINGS_FILTER_OPTS_REQUEST',
  SAVINGS_FILTER_OPTS_RESPONSE: 'app/savings/SAVINGS_FILTER_OPTS_RESPONSE',

  SAVINGS_OFFSETS_DOWNLOAD_REQUEST:
    'app/savings/SAVINGS_OFFSETS_DOWNLOAD_REQUEST',
  SAVINGS_OFFSETS_DOWNLOAD_RESPONSE:
    'app/savings/SAVINGS_OFFSETS_DOWNLOAD_RESPONSE',

  SAVINGS_OFFSETS_UPLOAD_REQUEST: 'app/savings/SAVINGS_OFFSETS_UPLOAD_REQUEST',
  SAVINGS_OFFSETS_UPLOAD_RESPONSE:
    'app/savings/SAVINGS_OFFSETS_UPLOAD_RESPONSE',
};

export const actions = {
  costsChartRequest: createAction(types.COSTS_CHART_REQUEST),
  costsChartResponse: createAction(types.COSTS_CHART_RESPONSE),
  costsOptsRequest: createAction(types.COSTS_OPTS_REQUEST),
  costsOptsResponse: createAction(types.COSTS_OPTS_RESPONSE),

  unitsChartRequest: createAction(types.UNITS_CHART_REQUEST),
  unitsChartResponse: createAction(types.UNITS_CHART_RESPONSE),
  unitsOptsRequest: createAction(types.UNITS_OPTS_REQUEST),
  unitsOptsResponse: createAction(types.UNITS_OPTS_RESPONSE),

  compareChartRequest: createAction(types.COMPARE_CHART_REQUEST),
  compareChartResponse: createAction(types.COMPARE_CHART_RESPONSE),
  compareOptsRequest: createAction(types.COMPARE_OPTS_REQUEST),
  compareOptsResponse: createAction(types.COMPARE_OPTS_RESPONSE),

  unitsCompareChartRequest: createAction(types.UNITS_COMPARE_CHART_REQUEST),
  unitsCompareChartResponse: createAction(types.UNITS_COMPARE_CHART_RESPONSE),
  unitsCompareOptsRequest: createAction(types.UNITS_COMPARE_OPTS_REQUEST),
  unitsCompareOptsResponse: createAction(types.UNITS_COMPARE_OPTS_RESPONSE),

  savingsTotalRequest: createAction(types.SAVINGS_TOTAL_REQUEST),
  savingsTotalResponse: createAction(types.SAVINGS_TOTAL_RESPONSE),

  savingsFilterOptsRequest: createAction(types.SAVINGS_FILTER_OPTS_REQUEST),
  savingsFilterOptsResponse: createAction(types.SAVINGS_FILTER_OPTS_RESPONSE),

  savingsOffsetsDownloadRequest: createAction(
    types.SAVINGS_OFFSETS_DOWNLOAD_REQUEST
  ),
  savingsOffsetsDownloadResponse: createAction(
    types.SAVINGS_OFFSETS_DOWNLOAD_RESPONSE
  ),

  savingsOffsetsUploadRequest: createAction(
    types.SAVINGS_OFFSETS_UPLOAD_REQUEST
  ),
  savingsOffsetsUploadResponse: createAction(
    types.SAVINGS_OFFSETS_UPLOAD_RESPONSE
  ),
};

const defaultState = {
  isRefreshing: false,
  costsOpts: null,
  unitsOpts: null,
  filterOpts: null,
  fileDownloading: false,
  fileUploading: false,
  fileUploadResult: null,
};

// - - - FETCH SAVINGS COSTS CHART - - -
// - - - FETCH SAVINGS COSTS CHART - - -
// - - - FETCH SAVINGS COSTS CHART - - -

export const fetchSavingsCostsChart = (
  buildingIds,
  billSourceIds,
  billSourceGroupIds,
  beginDate,
  endDate,
  costSourceType,
  includeBaseline,
  configId = null,
  offsetIds = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.costsChartRequest());
  try {
    const { data } = await api.savings.costsChart(
      buildingIds,
      billSourceIds.length ? billSourceIds : [],
      billSourceGroupIds.length ? billSourceGroupIds : [],
      beginDate,
      endDate,
      costSourceType,
      includeBaseline,
      configId,
      offsetIds
    );
    data.chart.isValid = data.chart.data.datasets.length > 0 ? true : false;

    return dispatch(actions.costsChartResponse(data));
  } catch (err) {
    return dispatch(actions.costsChartResponse(err));
  }
};

// - - - FETCH SAVINGS COSTS OPTS - - -
// - - - FETCH SAVINGS COSTS OPTS - - -
// - - - FETCH SAVINGS COSTS OPTS - - -

export const fetchSavingsCostsOpts = (
  buildingIds,
  beginDate,
  endDate
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.costsOptsRequest());
  try {
    const { data } = await api.savings.costsChart(
      buildingIds,
      [],
      [],
      beginDate,
      endDate,
      0,
      false
    );
    return dispatch(actions.costsOptsResponse(data));
  } catch (err) {
    return dispatch(actions.costsOptsResponse(err));
  }
};

// - - - FETCH SAVINGS UNITS CHART - - -
// - - - FETCH SAVINGS UNITS CHART - - -
// - - - FETCH SAVINGS UNITS CHART - - -

export const fetchSavingsUnitsChart = (
  buildingIds,
  billSourceIds,
  billSourceGroupIds,
  beginDate,
  endDate,
  costSourceType,
  includeBaseline,
  configId = 0,
  offsetIds = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.unitsChartRequest());
  try {
    const { data } = await api.savings.unitsChart(
      buildingIds,
      billSourceIds,
      billSourceGroupIds,
      beginDate,
      endDate,
      costSourceType,
      includeBaseline,
      configId,
      offsetIds
    );
    data.chart.isValid = data.chart.data.datasets.length > 0 ? true : false;
    return dispatch(actions.unitsChartResponse(data));
  } catch (err) {
    return dispatch(actions.unitsChartResponse(err));
  }
};

// - - - FETCH SAVINGS UNITS OPTS - - -
// - - - FETCH SAVINGS UNITS OPTS - - -
// - - - FETCH SAVINGS UNITS OPTS - - -

export const fetchSavingsUnitsOpts = (
  buildingIds,
  beginDate,
  endDate,
  configId = 0
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.unitsOptsRequest());
  try {
    const { data } = await api.savings.unitsChart(
      buildingIds,
      [],
      [],
      beginDate,
      endDate,
      0,
      false,
      configId
    );
    return dispatch(actions.unitsOptsResponse(data));
  } catch (err) {
    return dispatch(actions.unitsOptsResponse(err));
  }
};

// - - - COMPARE CHART - - -
// - - - COMPARE CHART - - -
// - - - COMPARE CHART - - -

export const fetchCompareChart = (
  buildingIds,
  billSourceIds,
  billSourceGroupIds,
  beginDate,
  endDate,
  costSourceType,
  includeBaseline,
  aliasId = null,
  configId = null,
  offsetIds = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.compareChartRequest());
  try {
    const { data } = await api.savings.costsComparisonChart(
      buildingIds,
      billSourceIds,
      billSourceGroupIds,
      beginDate,
      endDate,
      costSourceType,
      includeBaseline,
      aliasId,
      configId,
      offsetIds
    );

    if (!data.chart) {
      data.chart = { isValid: false, data: { datasets: [], labels: [] } };
    } else {
      data.chart.isValid = data.chart.data.datasets.length > 0 ? true : false;
    }

    return dispatch(actions.compareChartResponse(data));
  } catch (err) {
    return dispatch(actions.compareChartResponse(err));
  }
};

// - - - UNITS COMPARE CHART - - -
// - - - UNITS COMPARE CHART - - -
// - - - UNITS COMPARE CHART - - -

export const fetchUnitsCompareChart = (
  buildingIds,
  billSourceIds,
  billSourceGroupIds,
  beginDate,
  endDate,
  costSourceType,
  includeBaseline,
  aliasId = null,
  configId = 0,
  offsetIds = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.unitsCompareChartRequest());
  try {
    const { data } = await api.savings.unitsComparisonChart(
      buildingIds,
      billSourceIds,
      billSourceGroupIds,
      beginDate,
      endDate,
      costSourceType,
      includeBaseline,
      aliasId,
      configId,
      offsetIds
    );
    data.chart.isValid = data.chart.data.datasets.length > 0 ? true : false;
    return dispatch(actions.unitsCompareChartResponse(data));
  } catch (err) {
    return dispatch(actions.unitsCompareChartResponse(err));
  }
};

// - - - COMPARE OPTS - - -
// - - - COMPARE OPTS - - -
// - - - COMPARE OPTS - - -

export const fetchCompareOpts = (buildingIds, beginDate, endDate) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.compareOptsRequest());
  try {
    const { data } = await api.savings.costsComparisonChart(
      buildingIds,
      [],
      [],
      beginDate,
      endDate,
      0,
      false
    );
    return dispatch(actions.compareOptsResponse(data));
  } catch (err) {
    return dispatch(actions.compareOptsResponse(err));
  }
};

// - - - UNIT COMPARE OPTS - - -
// - - - UNIT COMPARE OPTS - - -
// - - - UNIT COMPARE OPTS - - -

export const fetchUnitCompareOpts = (
  buildingIds,
  beginDate,
  endDate,
  aliasId,
  configId = 0
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.unitsCompareOptsRequest());
  try {
    const { data } = await api.savings.unitsComparisonChart(
      buildingIds,
      [],
      [],
      beginDate,
      endDate,
      0,
      false,
      aliasId,
      configId
    );
    return dispatch(actions.unitsCompareOptsResponse(data));
  } catch (err) {
    return dispatch(actions.unitsCompareOptsResponse(err));
  }
};

// - - - FETCH SAVINGS TOTAL - - -
// - - - FETCH SAVINGS TOTAL - - -
// - - - FETCH SAVINGS TOTAL - - -

export const fetchSavingsTotal = (
  buildingIds,
  beginDate,
  endDate,
  aliasId = null,
  configId = null,
  offsetIds = []
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.savingsTotalRequest());
  try {
    const { data } = await api.utility.savingsTotal(
      buildingIds,
      beginDate,
      endDate,
      aliasId,
      configId,
      offsetIds
    );
    data.isValid = data.billSourceSavings.length > 0 ? true : false;
    return dispatch(actions.savingsTotalResponse(data));
  } catch (err) {
    return dispatch(actions.savingsTotalResponse(err));
  }
};

export const fetchSavingsFilterOptions = configId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.savingsFilterOptsRequest());
  try {
    const { data } = await api.savings.savingsFilterOptions(configId);
    return dispatch(actions.savingsFilterOptsResponse(data));
  } catch (err) {
    return dispatch(actions.savingsFilterOptsResponse(err));
  }
};

export const fetchOffsetsDownload = (scope, ids) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.savingsOffsetsDownloadRequest());
  try {
    const { data } = await api.savings.getOffsetsFile(scope, ids);
    let filename = `${scope}-offsets-${ids.toString().replace(',', '')}.xlsx`;
    download(data, filename);
    return dispatch(actions.savingsOffsetsDownloadResponse(data));
  } catch (err) {
    return dispatch(actions.savingsOffsetsDownloadResponse(err));
  }
};

export const uploadOffsets = (scope, ids, file) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.savingsOffsetsUploadRequest());
  try {
    const { data } = await api.savings.postOffsetsFile(scope, ids, file);
    return dispatch(actions.savingsOffsetsUploadResponse(data));
  } catch (err) {
    return dispatch(actions.savingsOffsetsUploadResponse(err));
  }
};

// - - - ACTIONS - - -
// - - - ACTIONS - - -
// - - - ACTIONS - - -

export default handleActions(
  {
    [actions.costsChartRequest]: {
      next: state => ({
        ...state,
        costsChart: null,
        isRefreshing: true,
      }),
    },

    [actions.costsChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        costsChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.costsOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.costsOptsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        costsOpts: payload.sourceMap,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    // - - - - - - -

    [actions.unitsChartRequest]: {
      next: state => ({
        ...state,
        unitsChart: null,
        isRefreshing: true,
      }),
    },

    [actions.unitsChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        unitsChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.unitsOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.unitsOptsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        unitsOpts: payload.sourceMap,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    // - - - - - - -

    [actions.compareChartRequest]: {
      next: state => ({
        ...state,
        compareChart: null,
        isRefreshing: true,
      }),
    },

    [actions.compareChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        compareChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.compareOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.compareOptsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        compareOpts: payload.sourceMap,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    // - - - - - - -
    [actions.unitsCompareChartRequest]: {
      next: state => ({
        ...state,
        unitsCompareChart: null,
        isRefreshing: true,
      }),
    },

    [actions.unitsCompareChartResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        unitsCompareChart: payload.chart,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    [actions.unitsCompareOptsRequest]: {
      next: state => ({
        ...state,
        isRefreshing: true,
      }),
    },

    [actions.unitsCompareOptsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        unitsCompareOpts: payload.sourceMap,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    // - - - - - - -

    [actions.savingsTotalRequest]: {
      next: state => ({
        ...state,
        totalSavings: null,
        isRefreshing: true,
      }),
    },

    [actions.savingsTotalResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        totalSavings: payload,
        isRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
        isRefreshing: false,
      }),
    },

    // - - - - - - -

    [actions.savingsFilterOptsRequest]: {
      next: state => ({
        ...state,
        filterOpts: null,
      }),
    },

    [actions.savingsFilterOptsResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        filterOpts: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.message,
      }),
    },

    [actions.savingsOffsetsDownloadRequest]: {
      next: state => ({
        ...state,
        fileDownloading: true,
      }),
    },

    [actions.savingsOffsetsDownloadResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        fileDownloading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.data,
        fileDownloading: false,
      }),
    },

    [actions.savingsOffsetsUploadRequest]: {
      next: state => ({
        ...state,
        fileUploading: true,
        fileUploadResult: null,
      }),
    },

    [actions.savingsOffsetsUploadResponse]: {
      next: (_state, { payload }) => ({
        ..._state,
        fileUploading: false,
        fileUploadResult: payload.data,
      }),

      throw: (state, { payload }) => ({
        ...state,
        message: payload.data,
        fileUploading: false,
      }),
    },
  },

  defaultState
);
