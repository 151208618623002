const getQueryStringList = (input, fieldName) => {
  let qString = '';
  for (let i = 0; i < input.length; i++) {
    qString += `&${fieldName}=${input[i]}`;
  }
  return qString;
};

//--- API calls ---
export class ConfigurationService {
  constructor(api) {
    this.api = api;
  }

  async getUsers() {
    let url = `/account`;
    return this.api.get(url);
  }
  async getUsersList() {
    let url = `/account/list`;
    return this.api.get(url);
  }

  async postUser(data) {
    let url = `/account/register`;
    return this.api.post(url, data);
  }

  async putUser(userId, data) {
    let url = `/account/${userId}`;
    return this.api.put(url, data);
  }

  async deleteUser(userId) {
    let url = `/account/${userId}`;
    return this.api.delete(url);
  }

  async getUserRoleOptions() {
    let url = `/account/roles`;
    return this.api.get(url);
  }

  async getSites() {
    let url = `/site/list`;
    return this.api.pyGet(url);
  }
  async getUnitConversionsOptions() {
    let url = `/profile/unit-conversions/options`;
    return this.api.get(url);
  }

  async getAdminSites() {
    let url = `/site/admin/list`;
    return this.api.pyGet(url);
  }

  async getBuildingGroup(groupId) {
    let url = `/building/group/${groupId}`;
    return this.api.get(url);
  }

  async putBuildingGroup(groupId, data) {
    let url = `/building/group/${groupId}`;
    return this.api.put(url, data);
  }
  async putBuildingGroupsBulk(data) {
    let url = `/building/group/batch`;
    return this.api.post(url, data);
  }

  async deleteBuildingGroup(groupId) {
    let url = `/building/group/${groupId}`;
    return this.api.delete(url);
  }

  async postBuildingToGroup(buildingId, groupId) {
    let url = `/building/${buildingId}/group/${groupId}`;
    return this.api.post(url);
  }

  async deleteBuildingFromGroup(buildingId, groupId) {
    let url = `/building/${buildingId}/group/${groupId}`;
    return this.api.delete(url);
  }

  async getBuildingGroupList(siteId, name = null, typeIds = []) {
    let url = `/building/group/list?siteId=${siteId}`;
    if (name !== null && name.length > 0) {
      url += `&name=${name}`;
    }
    url += getQueryStringList(typeIds, 'typeId');
    return this.api.get(url);
  }

  async getBuildingGroupTypeOptions() {
    let url = `profile/group-types/options`;
    return this.api.get(url);
  }
  async postBuildingGroupType(name) {
    let url = `building/group-types`;
    let payload = { name: name };
    return this.api.post(url, payload);
  }
  async putBuildingGroupType(groupTypeId, name) {
    let url = `building/group-types/${groupTypeId}`;
    let payload = { name: name };
    return this.api.put(url, payload);
  }

  async getFacilityDetailOptions() {
    let url = `profile/facility-details/options`;
    return this.api.get(url);
  }

  async getFloorplanPointClassOptions(siteId) {
    let url = `/trends/pointclasses?siteid=${siteId}`;
    return this.api.pyGet(url);
  }

  async postBuildingGroup(data) {
    let url = `/building/group`;
    return this.api.post(url, data);
  }

  async postBuildingImage(buildingId, data) {
    let url = `/building/${buildingId}/image`;
    return this.api.post(url, data);
  }
  async postClientImage(siteId, data) {
    let url = `/profile/${siteId}/image`;
  }

  async getBuilding(buildingId) {
    let url = `/building/${buildingId}`;
    return this.api.get(url);
  }

  async postProfileImage(data) {
    let url = `/profile/image`;
    return this.api.post(url, data);
  }

  async getProfileImageList() {
    let url = `/profile/image/list`;
    return this.api.get(url);
  }
  async getProfileList(siteIds) {
    let sitesQueryString = '';
    siteIds.forEach((s, i) => {
      if (i === 0) {
        sitesQueryString = sitesQueryString + `?siteId=${s}`;
      } else {
        sitesQueryString = sitesQueryString + `&siteId=${s}`;
      }
    });

    let url = `/profile/list${sitesQueryString}`;
    return this.api.get(url);
  }
  async getSimpleProfileList(siteIds) {
    let sitesQueryString = '';
    siteIds.forEach((s, i) => {
      if (i === 0) {
        sitesQueryString = sitesQueryString + `?siteId=${s}`;
      } else {
        sitesQueryString = sitesQueryString + `&siteId=${s}`;
      }
    });
    sitesQueryString = sitesQueryString + '&isSimple=true';

    let url = `/profile/list${sitesQueryString}`;
    return this.api.get(url);
  }

  async getMetricList(siteId) {
    let url = `/profile/metric/list?siteId=${siteId}`;
    return this.api.get(url);
  }
  async getMetricOptionsList() {
    let url = `/profile/metric/options/list`;
    return this.api.get(url);
  }
  async putMetric(metricId, data) {
    let url = `/profile/metric/${metricId}`;
    return this.api.put(url, data);
  }
  async deleteMetric(metricId) {
    let url = `/profile/metric/${metricId}`;
    return this.api.delete(url);
  }
  async postMetric(data) {
    let url = `/profile/metric`;
    return this.api.post(url, data);
  }
  async getPageOptions() {
    let url = `/profile/page/options/list`;
    return this.api.get(url);
  }
  async deleteProfile(profileId) {
    let url = `/profile/${profileId}`;
    return this.api.delete(url);
  }
  async putProfile(profileId, data) {
    let url = `/profile/${profileId}`;
    return this.api.put(url, data);
  }
  async postProfile(data) {
    let url = `/profile`;
    return this.api.post(url, data);
  }

  async addUsersToProfile(data) {
    let url = `/profile/users/add`;
    return this.api.post(url, data);
  }
  async removeUsersFromProfile(data) {
    let url = `/profile/users/remove`;
    return this.api.post(url, data);
  }
  async changeRoleForUsers(data) {
    let url = '/account/batch/role';
    return this.api.post(url, data);
  }
  async deleteUsers(data) {
    let url = '/account/batch/delete';
    return this.api.post(url, data);
  }
}
