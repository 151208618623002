import { PlaylistAddOutlined } from '@material-ui/icons';
import { createAction, handleActions } from 'redux-actions';

// - - - - - - - - - - - - -
// - - - ACTION  TYPES - - -
// - - - - - - - - - - - - -
export const types = {
  REPORTS_REQUEST: 'app/powerBiEmbedded/REPORTS_REQUEST',
  REPORTS_RESPONSE: 'app/powerBiEmbedded/REPORTS_RESPONSE',

  ENABLED_REPORTS_REQUEST: 'app/powerBiEmbedded/ENABLED_REPORTS_REQUEST',
  ENABLED_REPORTS_RESPONSE: 'app/powerBiEmbedded/ENABLED_REPORTS_RESPONSE',

  SET_CURRENT_REPORT: 'app/powerBiEmbedded/SET_CURRENT_REPORT',
  SET_REPORT_INFO_VALIDATE_ERROR:
    'app/powerBiEmbedded/SET_REPORT_INFO_VALIDATE_ERROR',

  REPORT_ADD_REQUEST: 'app/powerBiEmbedded/REPORT_ADD_REQUEST',
  REPORT_ADD_RESPONSE: 'app/powerBiEmbedded/REPORT_ADD_RESPONSE',

  REPORT_UPDATE_REQUEST: 'app/powerBiEmbedded/REPORT_UPDATE_REQUEST',
  REPORT_UPDATE_RESPONSE: 'app/powerBiEmbedded/REPORT_UPDATE_RESPONSE',

  REPORT_REMOVE_REQUEST: 'app/powerBiEmbedded/REPORT_REMOVE_REQUEST',
  REPORT_REMOVE_RESPONSE: 'app/powerBiEmbedded/REPORT_REMOVE_RESPONSE',

  REPORT_EMBED_REQUEST: 'app/powerBiEmbedded/REPORT_EMBED_REQUEST',
  REPORT_EMBED_RESPONSE: 'app/powerBiEmbedded/REPORT_EMBED_RESPONSE',
};

// - - - - - - - - - - - - - -
// - - - ACTION CREATORS - - -
// - - - - - - - - - - - - - -
export const actions = {
  reportsRequest: createAction(types.REPORTS_REQUEST),
  reportsResponse: createAction(types.REPORTS_RESPONSE),

  enabledReportsRequest: createAction(types.ENABLED_REPORTS_REQUEST),
  enabledReportsResponse: createAction(types.ENABLED_REPORTS_RESPONSE),

  setCurrentReport: createAction(types.SET_CURRENT_REPORT),
  setReportInfoValidateError: createAction(
    types.SET_REPORT_INFO_VALIDATE_ERROR
  ),

  reportAddRequest: createAction(types.REPORT_ADD_REQUEST),
  reportAddResponse: createAction(types.REPORT_ADD_RESPONSE),

  reportUpdateRequest: createAction(types.REPORT_UPDATE_REQUEST),
  reportUpdateResponse: createAction(types.REPORT_UPDATE_RESPONSE),

  reportRemoveRequest: createAction(types.REPORT_REMOVE_REQUEST),
  reportRemoveResponse: createAction(types.REPORT_REMOVE_RESPONSE),

  reportEmbedRequest: createAction(types.REPORT_EMBED_REQUEST),
  reportEmbedResponse: createAction(types.REPORT_EMBED_RESPONSE),
};

// - - - - - - - - - - - - - -
// - - - STARTING  STATE - - -
// - - - - - - - - - - - - - -
const defaultState = {
  siteReports: [],
  configReports: [],
  reportsRefreshing: false,
  reportsRefreshError: null,

  currentReport: null,

  reportAdditionInProgress: false,
  reportUpdateInProgress: false,
  reportDeletionInProgress: false,

  reportsModifyError: null,
  reportInfoValidationError: null,

  retrievingEmbedConfig: false,
  currentEmbedConfig: null,
  configRetrievalError: null,
};

// - - - - - - - - - - - - - - - - - - - -
// - - - API CALLS/ DISPATCH ACTIONS - - -
// - - - - - - - - - - - - - - - - - - - -

/**
 * Get all reports associated with a given siteId from API and update state
 * @param {number} siteId - id of site report is accessible for
 */
export const getSiteReports = siteId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.reportsRequest());
  try {
    const { data } = await api.powerBiEmbedded.getSiteReports(siteId);
    return dispatch(actions.reportsResponse(data));
  } catch (err) {
    return dispatch(actions.reportsResponse(err));
  }
};

/**
 * Get all reports enabled for a configId from API and update state
 * @param {number} configId - id of config report is enabled for
 */
export const getSiteConfigReports = configId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.enabledReportsRequest());
  try {
    const { data } = await api.powerBiEmbedded.getSiteConfigReports(configId);
    return dispatch(actions.enabledReportsResponse(data));
  } catch (err) {
    return dispatch(actions.enabledReportsResponse(err));
  }
};

/**
 * Updates state to reflect selected report in current report
 * @param {Object} selected - report identification and display data
 */
export const setCurrentReport = selected => async (
  dispatch,
  _getState,
  { api }
) => {
  return dispatch(actions.setCurrentReport(selected));
};

/**
 * Updates state to contain error if there was an issue validating report data inputs
 * @param {String} message - error message identifying validating issue
 */
export const setReportInfoValidateError = message => async (
  dispatch,
  _getState,
  { api }
) => {
  return dispatch(actions.setReportInfoValidateError(message));
};

/**
 * Ask API to add a report containing reportData for given siteId
 * update state to reflect new current report
 * @param {number} siteId - id of site report is to be added for
 * @param {Object} reportData - report identification and display data
 */
export const addReport = (siteId, reportData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.reportAddRequest());
  try {
    // get back the id for newly added report's entry
    const { data } = await api.powerBiEmbedded.addReport(siteId, reportData);
    // Add the returned id, and the siteId to report data to mimic its db entry
    var addedReportData = {
      id: data,
      siteId,
      ...reportData,
    };

    return dispatch(actions.reportAddResponse(addedReportData));
  } catch (err) {
    return dispatch(actions.reportAddResponse(err));
  }
};

/**
 * Ask API to update pre-existing report to hold new report data
 * @param {number} siteId - id of site report is accessible for
 * @param {number} entryId - id of db entry
 * @param {Object} newReportData - report identification and display data
 */
export const updateReport = (siteId, entryId, newReportData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.reportUpdateRequest());
  try {
    await api.powerBiEmbedded.updateReport(siteId, entryId, newReportData);
    // data that matches db entry for updated report
    var completeNewData = {
      id: entryId,
      siteId,
      ...newReportData,
    };
    return dispatch(actions.reportUpdateResponse(completeNewData));
  } catch (err) {
    return dispatch(actions.reportUpdateResponse(err));
  }
};

/**
 * Ask API to remove a report entry with given id
 * @param {number} siteId - id of site report is accessible for
 * @param {number} reportEntryId - id of db entry
 */
export const removeReport = (siteId, reportEntryId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.reportRemoveRequest());
  try {
    await api.powerBiEmbedded.removeReport(siteId, reportEntryId);
    return dispatch(actions.reportRemoveResponse());
  } catch (err) {
    return dispatch(actions.reportRemoveResponse(err));
  }
};

/**
 * Ask API to retrieve the configuration settings from db and
 * generate embed parameters for a given report
 * @param {number} siteId - id of site report is accessible for
 * @param {number} reportEntryId - the id of db report entry to get
 */
export const getEmbedConfig = (siteId, reportEntryId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.reportEmbedRequest());
  try {
    const { data } = await api.powerBiEmbedded.getEmbedConfig(
      siteId,
      reportEntryId
    );
    return dispatch(actions.reportEmbedResponse(data));
  } catch (err) {
    return dispatch(actions.reportEmbedResponse(err));
  }
};

// - - - - - - - - - - - - - -
// - - - HANDLE  ACTIONS - - -
// - - - - - - - - - - - - - -
export default handleActions(
  {
    [actions.reportsRequest]: {
      next: state => ({
        ...state,
        reportsRefreshError: null,
        reportsRefreshing: true,
      }),
    },

    [actions.reportsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        siteReports: payload,
        reportsRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        reportsRefreshError: payload.message,
        reportsRefreshing: false,
      }),
    },

    [actions.enabledReportsRequest]: {
      next: state => ({
        ...state,
        reportsRefreshing: true,
      }),
    },

    [actions.enabledReportsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        configReports: payload,
        reportsRefreshing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        reportsRefreshError: payload.message,
        reportsRefreshing: false,
      }),
    },

    [actions.setCurrentReport]: {
      next: (state, { payload }) => ({
        ...state,
        currentReport: payload,
      }),
    },

    [actions.setReportInfoValidateError]: {
      next: (state, { payload }) => ({
        ...state,
        reportInfoValidationError: payload,
      }),
    },

    [actions.reportAddRequest]: {
      next: state => ({
        ...state,
        reportsModifyError: null,
        reportAdditionInProgress: true,
      }),
    },

    [actions.reportAddResponse]: {
      next: (state, { payload }) => ({
        ...state,
        currentReport: payload,
        reportAdditionInProgress: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        reportsModifyError: payload.message,
        reportAdditionInProgress: false,
      }),
    },

    [actions.reportUpdateRequest]: {
      next: state => ({
        ...state,
        reportsModifyError: null,
        reportUpdateInProgress: true,
      }),
    },

    [actions.reportUpdateResponse]: {
      next: (state, { payload }) => ({
        ...state,
        currentReport: payload,
        reportUpdateInProgress: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        reportsModifyError: payload.message,
        reportUpdateInProgress: false,
      }),
    },

    [actions.reportRemoveRequest]: {
      next: state => ({
        ...state,
        reportsModifyError: null,
        reportDeletionInProgress: true,
      }),
    },

    [actions.reportRemoveResponse]: {
      next: state => ({
        ...state,
        currentReport: null,
        reportDeletionInProgress: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        reportsModifyError: payload.message,
        reportDeletionInProgress: false,
      }),
    },

    [actions.reportEmbedRequest]: {
      next: state => ({
        ...state,
        configRetrievalError: null,
        retrievingEmbedConfig: true,
      }),
    },

    [actions.reportEmbedResponse]: {
      next: (state, { payload }) => ({
        ...state,
        currentEmbedConfig: payload,
        retrievingEmbedConfig: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        currentEmbedConfig: null,
        configRetrievalError: payload.message,
        retrievingEmbedConfig: false,
      }),
    },
  },

  defaultState
);
